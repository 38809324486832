import {AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LoaderService} from './services/loader.service';
import {AuthenticationService} from './services/authentication.service';
import {ConfigAssetLoaderService} from './configAssetLoader.service';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import pack from './../../package.json';
import {GlobalStateService} from './services/global-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewChecked {

  version: string;

  loading: Subject<boolean> = new Subject<boolean>();

  displayInverseTab: boolean;
  authInverseTab: string[] = [
    'amilon100p',
    'bdouce100p',
    'bporte',
    'dchauv101p',
    'epetit102p',
    'fpomies',
    'kraynal',
    'pbouvret',
    'thibaubias'
  ];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private loaderService: LoaderService,
    private auth: AuthenticationService,
    private configAssetLoaderService: ConfigAssetLoaderService,
    private globalStateService: GlobalStateService,
    private snackBar: MatSnackBar,
    private router: Router) {

    this.loading = this.loaderService.loading;
  }

  ngOnInit() {
    this.version = pack.version;

    const hasTicket = window.location.href.match(/(.*)[&?]ticket=([^&?]*)$/);
    if (hasTicket) {
      const [, , ticket] = hasTicket;

      this.auth.login(ticket).subscribe(res => {
        localStorage.setItem('token', res.headers.get('Authorization'));
        this.displayInverseTab = this.authInverseTab.includes(res.body.uid);
        this.router.navigate(['']);
      });

    } else {
      this.auth.redirectToCasLogin();
    }
  }

  refresh() {
    this.router.navigate(['']);
    this.globalStateService.notifyDataChanged('refresh', true);
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

}
