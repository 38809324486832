import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConfigAssetLoaderService, Configuration} from '../configAssetLoader.service';

@Injectable()
export class AuthenticationService {

  conf: Configuration;

  constructor(private http: HttpClient, private configAssetLoaderService: ConfigAssetLoaderService) {
    this.configAssetLoaderService.loadConfigurations().subscribe(res => {
      this.conf = res;
    });
  }

  public redirectToCasLogin() {
    window.location.href = this.conf.casLogin + '?service=' + this.conf.ihmUrl + '&renew=1';  }

  public login(ticket: string): Observable<any> {
    return this.http.get<any>('/auth/login/cas/' + ticket, {observe: 'response'});
  }
}
